/*
A VUE Utilities plugin created by Marco Aurelio Zoqui
adopted from Zoqui's HCare usefull javascript tools and utilities 

hcare.utils.js functions are adapted and grouped here as a VUE plugin

Usage:

main.js (vue app entry point):
  import utils from './plugins/utils.js'
  Vue.use(utils)

any Vue component
  this.$utils.<function name>

*/

// It removes diacritics from a given string
// It also replaces some of non printable characters from standard ABNT2 BR Keyboard (maz);
// tests:
// a="abcdefghijklmnopqrstuvxwyzABCDEFGHIJKLMNOPQRSTUVXWYZ0123456789çéćóíúáúãõẽ|\/-_*§[{]}°ºª'?`><:;.,"
// app.__vue__.$utils.removeDiacritics(a)
//
const removeDiacritics = (istr) => {
  if (typeof istr != "string") return istr;
  const m = [
    ["A", /[\u0041\u24B6\uFF21\u00C0\u00C1\u00C2\u1EA6\u1EA4\u1EAA\u1EA8\u00C3\u0100\u0102\u1EB0\u1EAE\u1EB4\u1EB2\u0226\u01E0\u00C4\u01DE\u1EA2\u00C5\u01FA\u01CD\u0200\u0202\u1EA0\u1EAC\u1EB6\u1E00\u0104\u023A\u2C6F]/g],
    ["AA", /[\uA732]/g],
    ["AE", /[\u00C6\u01FC\u01E2]/g],
    ["AO", /[\uA734]/g],
    ["AU", /[\uA736]/g],
    ["AV", /[\uA738\uA73A]/g],
    ["AY", /[\uA73C]/g],
    ["B", /[\u0042\u24B7\uFF22\u1E02\u1E04\u1E06\u0243\u0182\u0181]/g],
    ["C", /[\u0043\u24B8\uFF23\u0106\u0108\u010A\u010C\u00C7\u1E08\u0187\u023B\uA73E]/g],
    ["D", /[\u0044\u24B9\uFF24\u1E0A\u010E\u1E0C\u1E10\u1E12\u1E0E\u0110\u018B\u018A\u0189\uA779]/g],
    ["DZ", /[\u01F1\u01C4]/g],
    ["Dz", /[\u01F2\u01C5]/g],
    ["E", /[\u0045\u24BA\uFF25\u00C8\u00C9\u00CA\u1EC0\u1EBE\u1EC4\u1EC2\u1EBC\u0112\u1E14\u1E16\u0114\u0116\u00CB\u1EBA\u011A\u0204\u0206\u1EB8\u1EC6\u0228\u1E1C\u0118\u1E18\u1E1A\u0190\u018E]/g],
    ["F", /[\u0046\u24BB\uFF26\u1E1E\u0191\uA77B]/g],
    ["G", /[\u0047\u24BC\uFF27\u01F4\u011C\u1E20\u011E\u0120\u01E6\u0122\u01E4\u0193\uA7A0\uA77D\uA77E]/g],
    ["H", /[\u0048\u24BD\uFF28\u0124\u1E22\u1E26\u021E\u1E24\u1E28\u1E2A\u0126\u2C67\u2C75\uA78D]/g],
    ["I", /[\u0049\u24BE\uFF29\u00CC\u00CD\u00CE\u0128\u012A\u012C\u0130\u00CF\u1E2E\u1EC8\u01CF\u0208\u020A\u1ECA\u012E\u1E2C\u0197]/g],
    ["J", /[\u004A\u24BF\uFF2A\u0134\u0248]/g],
    ["K", /[\u004B\u24C0\uFF2B\u1E30\u01E8\u1E32\u0136\u1E34\u0198\u2C69\uA740\uA742\uA744\uA7A2]/g],
    ["L", /[\u004C\u24C1\uFF2C\u013F\u0139\u013D\u1E36\u1E38\u013B\u1E3C\u1E3A\u0141\u023D\u2C62\u2C60\uA748\uA746\uA780]/g],
    ["LJ", /[\u01C7]/g],
    ["Lj", /[\u01C8]/g],
    ["M", /[\u004D\u24C2\uFF2D\u1E3E\u1E40\u1E42\u2C6E\u019C]/g],
    ["N", /[\u004E\u24C3\uFF2E\u01F8\u0143\u00D1\u1E44\u0147\u1E46\u0145\u1E4A\u1E48\u0220\u019D\uA790\uA7A4]/g],
    ["NJ", /[\u01CA]/g],
    ["Nj", /[\u01CB]/g],
    ["O", /[\u004F\u24C4\uFF2F\u00D2\u00D3\u00D4\u1ED2\u1ED0\u1ED6\u1ED4\u00D5\u1E4C\u022C\u1E4E\u014C\u1E50\u1E52\u014E\u022E\u0230\u00D6\u022A\u1ECE\u0150\u01D1\u020C\u020E\u01A0\u1EDC\u1EDA\u1EE0\u1EDE\u1EE2\u1ECC\u1ED8\u01EA\u01EC\u00D8\u01FE\u0186\u019F\uA74A\uA74C]/g],
    ["OI", /[\u01A2]/g],
    ["OO", /[\uA74E]/g],
    ["OU", /[\u0222]/g],
    ["P", /[\u0050\u24C5\uFF30\u1E54\u1E56\u01A4\u2C63\uA750\uA752\uA754]/g],
    ["Q", /[\u0051\u24C6\uFF31\uA756\uA758\u024A]/g],
    ["R", /[\u0052\u24C7\uFF32\u0154\u1E58\u0158\u0210\u0212\u1E5A\u1E5C\u0156\u1E5E\u024C\u2C64\uA75A\uA7A6\uA782]/g],
    ["S", /[\u0053\u24C8\uFF33\u1E9E\u015A\u1E64\u015C\u1E60\u0160\u1E66\u1E62\u1E68\u0218\u015E\u2C7E\uA7A8\uA784]/g],
    ["T", /[\u0054\u24C9\uFF34\u1E6A\u0164\u1E6C\u021A\u0162\u1E70\u1E6E\u0166\u01AC\u01AE\u023E\uA786]/g],
    ["TZ", /[\uA728]/g],
    ["U", /[\u0055\u24CA\uFF35\u00D9\u00DA\u00DB\u0168\u1E78\u016A\u1E7A\u016C\u00DC\u01DB\u01D7\u01D5\u01D9\u1EE6\u016E\u0170\u01D3\u0214\u0216\u01AF\u1EEA\u1EE8\u1EEE\u1EEC\u1EF0\u1EE4\u1E72\u0172\u1E76\u1E74\u0244]/g],
    ["V", /[\u0056\u24CB\uFF36\u1E7C\u1E7E\u01B2\uA75E\u0245]/g],
    ["VY", /[\uA760]/g],
    ["W", /[\u0057\u24CC\uFF37\u1E80\u1E82\u0174\u1E86\u1E84\u1E88\u2C72]/g],
    ["X", /[\u0058\u24CD\uFF38\u1E8A\u1E8C]/g],
    ["Y", /[\u0059\u24CE\uFF39\u1EF2\u00DD\u0176\u1EF8\u0232\u1E8E\u0178\u1EF6\u1EF4\u01B3\u024E\u1EFE]/g],
    ["Z", /[\u005A\u24CF\uFF3A\u0179\u1E90\u017B\u017D\u1E92\u1E94\u01B5\u0224\u2C7F\u2C6B\uA762]/g],
    ["a", /[\u0061\u24D0\uFF41\u1E9A\u00E0\u00E1\u00E2\u1EA7\u1EA5\u1EAB\u1EA9\u00E3\u0101\u0103\u1EB1\u1EAF\u1EB5\u1EB3\u0227\u01E1\u00E4\u01DF\u1EA3\u00E5\u01FB\u01CE\u0201\u0203\u1EA1\u1EAD\u1EB7\u1E01\u0105\u2C65\u0250]/g],
    ["aa", /[\uA733]/g],
    ["ae", /[\u00E6\u01FD\u01E3]/g],
    ["ao", /[\uA735]/g],
    ["au", /[\uA737]/g],
    ["av", /[\uA739\uA73B]/g],
    ["ay", /[\uA73D]/g],
    ["b", /[\u0062\u24D1\uFF42\u1E03\u1E05\u1E07\u0180\u0183\u0253]/g],
    ["c", /[\u0063\u24D2\uFF43\u0107\u0109\u010B\u010D\u00E7\u1E09\u0188\u023C\uA73F\u2184]/g],
    ["d", /[\u0064\u24D3\uFF44\u1E0B\u010F\u1E0D\u1E11\u1E13\u1E0F\u0111\u018C\u0256\u0257\uA77A]/g],
    ["dz", /[\u01F3\u01C6]/g],
    ["e", /[\u0065\u24D4\uFF45\u00E8\u00E9\u00EA\u1EC1\u1EBF\u1EC5\u1EC3\u1EBD\u0113\u1E15\u1E17\u0115\u0117\u00EB\u1EBB\u011B\u0205\u0207\u1EB9\u1EC7\u0229\u1E1D\u0119\u1E19\u1E1B\u0247\u025B\u01DD]/g],
    ["f", /[\u0066\u24D5\uFF46\u1E1F\u0192\uA77C]/g],
    ["g", /[\u0067\u24D6\uFF47\u01F5\u011D\u1E21\u011F\u0121\u01E7\u0123\u01E5\u0260\uA7A1\u1D79\uA77F]/g],
    ["h", /[\u0068\u24D7\uFF48\u0125\u1E23\u1E27\u021F\u1E25\u1E29\u1E2B\u1E96\u0127\u2C68\u2C76\u0265]/g],
    ["hv", /[\u0195]/g],
    ["i", /[\u0069\u24D8\uFF49\u00EC\u00ED\u00EE\u0129\u012B\u012D\u00EF\u1E2F\u1EC9\u01D0\u0209\u020B\u1ECB\u012F\u1E2D\u0268\u0131]/g],
    ["j", /[\u006A\u24D9\uFF4A\u0135\u01F0\u0249]/g],
    ["k", /[\u006B\u24DA\uFF4B\u1E31\u01E9\u1E33\u0137\u1E35\u0199\u2C6A\uA741\uA743\uA745\uA7A3]/g],
    ["l", /[\u006C\u24DB\uFF4C\u0140\u013A\u013E\u1E37\u1E39\u013C\u1E3D\u1E3B\u017F\u0142\u019A\u026B\u2C61\uA749\uA781\uA747]/g],
    ["lj", /[\u01C9]/g],
    ["m", /[\u006D\u24DC\uFF4D\u1E3F\u1E41\u1E43\u0271\u026F]/g],
    ["n", /[\u006E\u24DD\uFF4E\u01F9\u0144\u00F1\u1E45\u0148\u1E47\u0146\u1E4B\u1E49\u019E\u0272\u0149\uA791\uA7A5]/g],
    ["nj", /[\u01CC]/g],
    ["o", /[\u006F\u24DE\uFF4F\u00F2\u00F3\u00F4\u1ED3\u1ED1\u1ED7\u1ED5\u00F5\u1E4D\u022D\u1E4F\u014D\u1E51\u1E53\u014F\u022F\u0231\u00F6\u022B\u1ECF\u0151\u01D2\u020D\u020F\u01A1\u1EDD\u1EDB\u1EE1\u1EDF\u1EE3\u1ECD\u1ED9\u01EB\u01ED\u00F8\u01FF\u0254\uA74B\uA74D\u0275]/g],
    ["oi", /[\u01A3]/g],
    ["ou", /[\u0223]/g],
    ["oo", /[\uA74F]/g],
    ["p", /[\u0070\u24DF\uFF50\u1E55\u1E57\u01A5\u1D7D\uA751\uA753\uA755]/g],
    ["q", /[\u0071\u24E0\uFF51\u024B\uA757\uA759]/g],
    ["r", /[\u0072\u24E1\uFF52\u0155\u1E59\u0159\u0211\u0213\u1E5B\u1E5D\u0157\u1E5F\u024D\u027D\uA75B\uA7A7\uA783]/g],
    ["s", /[\u0073\u24E2\uFF53\u00DF\u015B\u1E65\u015D\u1E61\u0161\u1E67\u1E63\u1E69\u0219\u015F\u023F\uA7A9\uA785\u1E9B]/g],
    ["t", /[\u0074\u24E3\uFF54\u1E6B\u1E97\u0165\u1E6D\u021B\u0163\u1E71\u1E6F\u0167\u01AD\u0288\u2C66\uA787]/g],
    ["tz", /[\uA729]/g],
    ["u", /[\u0075\u24E4\uFF55\u00F9\u00FA\u00FB\u0169\u1E79\u016B\u1E7B\u016D\u00FC\u01DC\u01D8\u01D6\u01DA\u1EE7\u016F\u0171\u01D4\u0215\u0217\u01B0\u1EEB\u1EE9\u1EEF\u1EED\u1EF1\u1EE5\u1E73\u0173\u1E77\u1E75\u0289]/g],
    ["v", /[\u0076\u24E5\uFF56\u1E7D\u1E7F\u028B\uA75F\u028C]/g],
    ["vy", /[\uA761]/g],
    ["w", /[\u0077\u24E6\uFF57\u1E81\u1E83\u0175\u1E87\u1E85\u1E98\u1E89\u2C73]/g],
    ["x", /[\u0078\u24E7\uFF58\u1E8B\u1E8D]/g],
    ["y", /[\u0079\u24E8\uFF59\u1EF3\u00FD\u0177\u1EF9\u0233\u1E8F\u00FF\u1EF7\u1E99\u1EF5\u01B4\u024F\u1EFF]/g],
    ["z", /[\u007A\u24E9\uFF5A\u017A\u1E91\u017C\u017E\u1E93\u1E95\u01B6\u0225\u0240\u2C6C\uA763]/g],
    ["?", /[\u00B0\u00BA\u00AA\u00A7]/g] //°ºª§
  ];
  for (var i = 0; i < m.length; i++)
    istr = istr.replace(m[i][1], m[i][0]);
  return istr;
};

/*
  returns true if string is found on object 
  qs = query string
  object = object to search  
  atr = Attributes to consider - when not provided it will search on the flat object.
    For deep search provide the list of attributes or the keyword "any" or "all"
*/
const queryStrAtr = (qs, obj, atr) => {
  if (!qs) return true;
  if (!obj) return false;
  let any = false;
  let keys = null;
  if (atr) {
    if (atr == "all" || atr == "any") {
      any = true;
    }
    keys = atr.split(",");
  }
  qs = removeDiacritics(qs).toUpperCase();
  let found = false;
  let search = function(qs, obj) {
    for (var k in obj) {
      let validAtr =
        any || !keys || (keys && keys.length && keys.indexOf(k) >= 0);
      if (typeof obj[k] == "object" && (any || keys)) {
        // only perform deep search if atr was defined
        if (search(qs, obj[k])) {
          found = true;
        }
      } else {
        if (
          validAtr &&
          (removeDiacritics(obj[k]) || "")
            .toString()
            .toUpperCase()
            .indexOf(qs) >= 0
        ) {
          found = true;
        }
      }
      if (found) {
        break;
      }
    }
    return found;
  };
  return search(qs, obj);
};

/*
return a list of hashtags or convert them to simple tags (lower case without hash mark and text without any hashtag)
*/
const hashtags = (text, simplify) => {
  // let lst=(text||"").match(/\B\#\w\w+\b/g)||[];
  let lst = (text || "").match(/\B\#\w+([0-9a-z\-\_]{1,10})\b/gi) || [];
  let ret = {
    text: text,
    tags: lst
  }
  if (simplify) {
    ret.tags = lst.map((tag) => {
      ret.text = ret.text.replace(new RegExp(tag, "i"), '');
      return tag.toLowerCase().replace(/\#/, "");
    })
    ret.text = ret.text.replace(/\s\s+/g, ' ').replace(/^\s+|\s+$/g, '');
  }
  return ret;
}

const onlyNumbers = (value) => {
  return (value + '').replace(/\D/g, '');
}
const validNumber = (avalue) => {
  avalue = (avalue + '').match(/^-?\d*(\.\d+)?$/);
  if (avalue) return true;
  return false;
}
const validNumberDecimals = (bvalue, decimalQtty) => {
  if (!validNumber(bvalue)) return false;
  let strvalue = parseFloat(bvalue) + '';
  let decimals = strvalue.split('.').length > 1 ? strvalue.split('.')[1].length : 0;
  if (decimals <= decimalQtty) return true;
  return false;
}

const loadCSS = (filename) => {
  if (!filename) return;
  var fileref = document.createElement("link");
  fileref.setAttribute("rel", "stylesheet");
  fileref.setAttribute("type", "text/css");
  fileref.setAttribute("href", filename);
  document.getElementsByTagName("head")[0].appendChild(fileref);
};

const applyCSSTheme = (name) => {
  if (!name) return;
  let prvTheme = window.localStorage.getItem("css_theme") || "skin-black-light";
  if (prvTheme != name) {
    $("body")
      .removeClass(prvTheme)
      .addClass(name);
    window.localStorage.setItem("css_theme", name)
  }
};

export { queryStrAtr, hashtags, onlyNumbers, validNumber, validNumberDecimals, loadCSS, applyCSSTheme };

export default {


  install(Vue) {
    if (Vue && Vue.prototype) {
      Object.defineProperty(Vue.prototype, "$utils", { value: this });
    }
  },

  capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  },

  proper(str) {
    return str.replace(/\w\S*/g, function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  },

  trim(s, chr) {
    if (s && typeof s == "string") {
      return s
        .replace(/(\s+|\r?\n|\r|\t)/g, chr ?? " ").replace(/^\s+|\s+$/g, "");
    }
    return s;
  },

  isEmpty(value) {
    return (
      value === "" ||
      value === null ||
      value === undefined ||
      value.length === 0
    );
  },

  asNumber(value) {
    var vlr = value || "";
    var tst = vlr.replace(/,/g, ".").match(/[0123456789.]+/g);
    if (tst) {
      return parseFloat(tst[0], 10).toFixed(2);
    }
    return vlr;
  },

  isValidURL(text) {
    var re = new RegExp(
      /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi
    );
    return text.match(re) ? true : false;
  },

  isUUID(uuid) {
    return /^([0-9a-fA-F\-]{8}|[0-9a-fA-F\-]{36})$/.test(uuid);
  },

  kebabTocamel(str) {
    let arr = str.split("-");
    let capital = arr.map((item, index) =>
      index
        ? item.charAt(0).toUpperCase() + item.slice(1).toLowerCase()
        : item.toLowerCase()
    );
    return capital.join("");
  },

  camelToKebab(str) {
    return str.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, "$1-$2").toLowerCase();
  },

  // returns the kebab version of the given string
  kebab(str) {
    return this.trim(
      this.removeDiacritics(str.replace(/\-/g, " ")).replace(
        /(^\d+|[^a-zA-Z0-9 _-])/g,
        ""
      ),
      "-"
    ).toLowerCase();
  },

  currency(value) {
    let vlr = this.asNumber(value);
    if (vlr && vlr.indexOf(".") >= 0) {
      return (vlr + "").split(".")[0];
    }
    return 0;
  },

  cents(value) {
    let vlr = this.asNumber(value);
    if (vlr && vlr.indexOf(".") >= 0) {
      let p = (vlr + "").split(".");
      let c = (p.length > 0 ? p[1] : "0").substring(0, 2);
      return c.length < 2 ? c + "0" : c;
    }
    return 0;
  },

  numberToReal(value) {
    value = value.toLocaleString('pt-BR');
    if (!value.includes(',')) return value + ',00';
    if (value.split(',')[1].length == 1) return value + '0'
    return value;
  },

  asKey(str) {
    if (str && typeof str == "string") {
      return this.proper(
        this.removeDiacritics(this.trim(str) || "").replace(/-/g, " ")
      ).replace(/\s/g, "");
    }
    return str;
  },

  asLabel(str) {
    return (str || "")
      .replace(/\/{2,}/g, "/")
      .split("/")
      .map((p) => this.kebab(p))
      .join("/");
  },

  compareStr(a, b) {
    return this.asKey(a).toLowerCase() == this.asKey(b).toLowerCase();
  },

  /*
  returns a bit of N from its I position
  4 == 0101 // bit(4,0)==1  //bit(4,1)==0  //bit(4,2)==1  //bit(4,3)==0
  */
  bit(n, i) {
    return (n >> i) % 2;
  },

  // generic mobile browser validation
  isMobile() {
    return [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i
    ].some((i) => {
      return navigator.userAgent.match(i);
    });
  },

  iOS() {
    return (
      [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod"
      ].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    );
  },

  navigator() {
    var t;
    var b =
      navigator.userAgent.match(
        /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
      ) || [];
    if (/trident/i.test(b[1])) {
      t = /\brv[ :]+(\d+)/g.exec(navigator.userAgent) || [];
      return "IE " + (t[1] || "");
    }
    if (b[1] === "Chrome") {
      t = navigator.userAgent.match(/\b(OPR|Edge)\/(\d+)/);
      if (t != null)
        return t
          .slice(1)
          .join(" ")
          .replace("OPR", "Opera");
    }
    b = b[2] ? [b[1], b[2]] : [navigator.appName, navigator.appVersion, "-?"];
    if ((t = navigator.userAgent.match(/version\/(\d+)/i)) != null)
      b.splice(1, 1, t[1]);
    return b.join(" ");
  },

  navigatorVersion() {
    return this.navigator().split(" ")[1];
  },

  // return 0 if not, and firefox version number if yes.
  isFirefox() {
    return navigator.userAgent.search("Firefox") >= 0
      ? this.navigatorVersion()
      : 0;
  },

  // returns a query string property
  gup(atr, url) {
    var params = {};
    var pairs = (url || document.URL).split("?")
      .pop()
      .split("&");
    for (var i = 0, p; i < pairs.length; i++) {
      p = pairs[i].split("=");
      params[p[0]] = decodeURIComponent(p[1]);
    }
    if (atr) {
      if (atr in params) {
        return params[atr];
      } else {
        return "";
      }
    }
    return params;
  },

  // convert and add object parameter as a url query string
  buildUrl(url, obj) {
    if (obj) {
      if (url.index("?") == -1) {
        url += "?";
      }
      for (var prop in obj) {
        let vlr = encodeURIComponent(obj[prop]);
        url += `&${prop}=${vlr}`;
      }
    }
    return url;
  },

  // same as buildUrl, but non destructive. It preserves existing parameters
  buildUrlSafe(url, obj) {
    var str = [];
    if (Object.keys(obj || {}).length) {
      var existing = {};
      var prev = (url || "").indexOf("?") < 0 ?
        [] :
        url.split("?").pop().split("&");
      if (prev.length) {
        prev.forEach((pv) => {
          var p = pv.split("=");
          existing[decodeURIComponent(p[0])] = "";
          if (p.length > 1) {
            existing[decodeURIComponent(p[0])] = decodeURIComponent(p[1])
          }
        });
      }
      for (var p in obj) {
        if (obj.hasOwnProperty(p)) {
          var v = (p in existing) ? existing[p] : obj[p];
          v = encodeURIComponent(p) + ((v === "") ? "" : "=" + encodeURIComponent(v));
          str.push(v);
        }
      }
    }
    if (url) {
      url = url.split("?")[0]
      url += ("?" + str.join("&"));
      return url;
    }
    else {
      return str.join("&");
    }
  },

  hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      }
      : null;
  },

  rgbToHex(r, g, b) {
    var componentToHex = function(c) {
      var hex = c.toString(16);
      return hex.length == 1 ? "0" + hex : hex;
    };
    return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
  },

  // return a color between two colors based on percentage variation
  colorVariation(perc, color1, color2) {
    var c1 = this.hexToRgb(color1 || "#008d4c");
    var c2 = this.hexToRgb(color2 || "#e08e0b");
    var p = (perc < 0 ? 0 : perc > 100 ? 100 : perc) / 100;
    return this.rgbToHex(
      parseInt(c1.r + p * (c2.r - c1.r)),
      parseInt(c1.g + p * (c2.g - c1.g)),
      parseInt(c1.b + p * (c2.b - c1.b))
    );
  },

  removeDiacritics(str) {
    return removeDiacritics(str);
  },

  favicon(fname) {
    var link =
      document.querySelector("link[rel*='icon']") ||
      document.createElement("link");
    link.type = "image/x-icon";
    link.rel = "shortcut icon";
    link.href = fname;
    document.getElementsByTagName("head")[0].appendChild(link);
  },

  toClipboard(text) {
    var el = document.createElement("input");
    el.value = text;
    document.body.appendChild(el);
    el.select();
    el.setSelectionRange(0, 99999);
    document.execCommand("copy");
    el.remove();
    return;
  },

  /*
  copy source properties if it exists on the destination (not a merge)
  */
  copyIfExists(src, dst) {
    const copy = (src, dst) => {
      for (var p in dst) {
        if (src && p in src) {
          if (typeof src[p] == "object") {
            copy(src[p], dst[p]);
          } else {
            dst[p] = src[p];
          }
        }
      }
    };
    if (src && dst && typeof src == "object" && typeof dst == "object") {
      copy(src, dst);
    }
  },

  convert(match, nosign) {
    if (nosign) {
      match.sign = "";
    } else {
      match.sign = match.negative ? "-" : match.sign;
    }
    var l = match.min - match.argument.length + 1 - match.sign.length;
    var pad = new Array(l < 0 ? 0 : l).join(match.pad);
    if (!match.left) {
      if (match.pad == "0" || nosign) {
        return match.sign + pad + match.argument;
      } else {
        return pad + match.sign + match.argument;
      }
    } else {
      if (match.pad == "0" || nosign) {
        return match.sign + match.argument + pad.replace(/0/g, " ");
      } else {
        return match.sign + match.argument + pad;
      }
    }
  },

  sprintf() {
    if (typeof arguments == "undefined") {
      return null;
    }
    if (arguments.length < 1) {
      return null;
    }
    if (typeof arguments[0] != "string") {
      return null;
    }
    if (typeof RegExp == "undefined") {
      return null;
    }
    var self = this;
    var string = arguments[0];
    var exp = new RegExp(
      /(%([%]|(-)?(\+|\x20)?(0)?(\d+)?(\.(\d)?)?([bcdfosxX])))/g
    );
    var matches = new Array();
    var strings = new Array();
    var convCount = 0;
    var stringPosStart = 0;
    var stringPosEnd = 0;
    var matchPosEnd = 0;
    var newString = "";
    var match = null;
    var substitution = "";

    while ((match = exp.exec(string))) {
      if (match[9]) {
        convCount += 1;
      }
      stringPosStart = matchPosEnd;
      stringPosEnd = exp.lastIndex - match[0].length;
      strings[strings.length] = string.substring(stringPosStart, stringPosEnd);
      matchPosEnd = exp.lastIndex;
      matches[matches.length] = {
        match: match[0],
        left: match[3] ? true : false,
        sign: match[4] || "",
        pad: match[5] || " ",
        min: match[6] || 0,
        precision: match[8],
        code: match[9] || "%",
        negative: parseFloat(arguments[convCount]) < 0 ? true : false,
        argument: String(arguments[convCount])
      };
    }

    strings[strings.length] = string.substring(matchPosEnd);
    if (matches.length == 0) {
      return string;
    }
    if (arguments.length - 1 < convCount) {
      return null;
    }
    match = null;
    var i = null;

    for (i = 0; i < matches.length; i++) {
      if (matches[i].code == "%") {
        substitution = "%";
      } else if (matches[i].code == "b") {
        matches[i].argument = String(
          Math.abs(parseInt(matches[i].argument)).toString(2)
        );
        substitution = self.convert(matches[i], true);
      } else if (matches[i].code == "c") {
        matches[i].argument = String(
          String.fromCharCode(parseInt(Math.abs(parseInt(matches[i].argument))))
        );
        substitution = self.convert(matches[i], true);
      } else if (matches[i].code == "d") {
        matches[i].argument = String(Math.abs(parseInt(matches[i].argument)));
        substitution = self.convert(matches[i]);
      } else if (matches[i].code == "f") {
        matches[i].argument = String(
          Math.abs(parseFloat(matches[i].argument)).toFixed(
            matches[i].precision ? matches[i].precision : 6
          )
        );
        substitution = self.convert(matches[i]);
      } else if (matches[i].code == "o") {
        matches[i].argument = String(
          Math.abs(parseInt(matches[i].argument)).toString(8)
        );
        substitution = self.convert(matches[i]);
      } else if (matches[i].code == "s") {
        matches[i].argument = matches[i].argument.substring(
          0,
          matches[i].precision
            ? matches[i].precision
            : matches[i].argument.length
        );
        substitution = self.convert(matches[i], true);
      } else if (matches[i].code == "x") {
        matches[i].argument = String(
          Math.abs(parseInt(matches[i].argument)).toString(16)
        );
        substitution = self.convert(matches[i]);
      } else if (matches[i].code == "X") {
        matches[i].argument = String(
          Math.abs(parseInt(matches[i].argument)).toString(16)
        );
        substitution = self.convert(matches[i]).toUpperCase();
      } else {
        substitution = matches[i].match;
      }
      newString += strings[i];
      newString += substitution;
    }
    newString += strings[i];
    return newString;
  },

  // format a single numeric value
  format(v, fmt) {
    return typeof fmt !== "string" ? v : this.sprintf(fmt, v);
  },

  elapsedTime(di) {
    if (!di) {
      return new Date().getTime();
    } else {
      //console.log(((new Date()).getTime()-di)+"ms "+ (info||''));
    }
  },

  /**
   * Converts a HTML table node into a MS Excel file
   * @param {Object} el    The DOM table element
   * @param {String} fname The file name - if not provided isodatetime is used
   */
  HTMLTable2XLS(el, fname, callback, reversed) {
    var _cb = callback || function() { };
    if (el) {
      var body = "";
      var i;
      if (reversed) {
        body = body + "<tr>" + el.rows[0].innerHTML + "</tr>";
        for (i = el.rows.length - 1; i > 0; i--) {
          body = body + "<tr>" + el.rows[i].innerHTML + "</tr>";
        }
      } else {
        for (i = 0; i < el.rows.length; i++) {
          body = body + "<tr>" + el.rows[i].innerHTML + "</tr>";
        }
      }
      if (body) {
        body = "<table border='2px'>" + body + "</table>";
        body = body
          .replace(/<A[^>]*>|<\/A>/g, "")
          .replace(/<img[^>]*>/gi, "")
          .replace(/<input[^>]*>|<\/input>/gi, ""); // reomves input params
        if (
          window.navigator.userAgent.indexOf("MSIE ") > 0 ||
          !!window.navigator.userAgent.match(/Trident.*rv:11\./)
        ) {
          var ifr = document.createElement("a");
          ifr.style.display = "none";
          document.body.appendChild(ifr);
          ifr.document.open("txt/html", "replace");
          ifr.document.write(body);
          ifr.document.close();
          ifr.focus();
          ifr.document.execCommand("SaveAs", true, fname + ".xls");
        } else {
          var e = document.createElement("a");
          document.body.appendChild(e);
          e.href =
            "data:application/vnd.ms-excel;charset=utf-8," +
            encodeURIComponent(body);
          e.download = fname + ".xls";
          e.click();
          document.body.removeChild(e);
        }
      }
    }
    _cb();
  },

  /**
   * Converts a HTML table node into a csv file
   * @param {Object} el    The DOM table element
   * @param {String} fname The file name - if not provided isodatetime is used
   */
  HTMLTable2CSV(el, fname, callback, reversed) {
    var self = this;
    var _cb = callback || function() { };
    if (el) {
      var rows = [];
      var row, cols, j, i;
      if (reversed) {
        row = el.rows[0];
        cols = [];
        for (j = 0; j < row.cells.length; j++) {
          cols.push(self.trim(row.cells[j].innerText));
        }
        rows.push(cols.join(","));
        for (i = el.rows.length - 1; i > 0; i--) {
          row = el.rows[i];
          cols = [];
          for (j = 0; j < row.cells.length; j++) {
            cols.push(self.trim(row.cells[j].innerText));
          }
          rows.push(cols.join(","));
        }
      } else {
        for (i = 0; i < el.rows.length; i++) {
          row = el.rows[i];
          cols = [];
          for (j = 0; j < row.cells.length; j++) {
            cols.push(self.trim(row.cells[j].innerText));
          }
          rows.push(cols.join(","));
        }
      }
      if (rows.length) {
        var body = rows.join("\n");
        var e = document.createElement("a");
        document.body.appendChild(e);
        e.href = "data:text/plain;charset=utf-8," + encodeURIComponent(body);
        e.download = fname + ".csv";
        e.addEventListener("click", (ev) => {
          ev.stopPropagation();
          document.body.removeChild(ev.target);
        });
        e.click();
      }
    }
    _cb();
  },

  download(data, mimetype, fname, new_tab = false) {
    let el = document.createElement("a");
    if (new_tab) el.target = '_blank';
    document.body.appendChild(el);
    if (/^(http|https).*/.test(data)) {
      el.href = data;
      el.download = mimetype || fname || new Date().getTime();
    } else {
      el.href =
        "data:" + mimetype + ";charset=utf-8," + encodeURIComponent(data);
      el.download = fname || new Date().getTime();
    }
    el.addEventListener("click", (ev) => {
      ev.stopPropagation();
      document.body.removeChild(ev.target);
    });
    el.click();
  },

  navigate(url, cfg) {
    let el = document.createElement("a");
    document.body.appendChild(el);
    if (cfg && cfg.new_tab) {
      el.target = "_blank";
      // todo: accept configuration to new window
    }
    el.href = url;
    el.click();
    document.body.removeChild(el);
  },

  qrcode_url(text, config) {
    config = config || {};
    let size = `${config?.size || 400}x${config?.size || 400}`;
    let content = encodeURIComponent(text);
    return `https://chart.googleapis.com/chart?cht=qr&choe=UTF-8&chs=${size}&chld=L|2&chl=${content}`;
  },

  /*
  Replace in obj all ${variable} defined in data 
  */
  replaceAll(obj, data) {
    if (obj && typeof obj == "object" && data && typeof data == "object") {
      var sobj = JSON.stringify(obj);
      for (var variable in data) {
        var re = new RegExp("\\$\\{" + variable + "\\}", "g");
        sobj = sobj.replace(re, data[variable]);
      }
      return JSON.parse(sobj);
    }
    return obj;
  },

  /*
  //console.log(sample_display);
  replaceValue(sample_display, 'data_id', 127, 'novo 127');
  replaceValue(sample_display, 'data_id', 129, 'novo 129');
  //console.log(sample_display);
  */
  replaceJsonValue(json, field, oldvalue, newvalue) {
    for (var k in json) {
      if (typeof json[k] == "object") {
        this.replaceJsonValue(json[k], field, oldvalue, newvalue);
      } else {
        if (k == field && json[k] == oldvalue) {
          json[k] = newvalue;
        }
      }
    }
  },

  renderDashboardConfiguration(tpl, tplData) {
    var self = this;
    var json = JSON.parse(JSON.stringify(tpl));
    if (tpl && tplData) {
      var _parse = function(field, obj) {
        for (var k in obj) {
          if (typeof obj[k] == "object") {
            _parse(k, obj[k]);
          } else {
            self.replaceJsonValue(json, field, k, obj[k]);
          }
        }
      };
      _parse("root", tplData);

      /*
      for(var oldValue in tplData){
        this.replaceJsonValue(json, 'data_id', oldValue, data[oldValue]);
      }
      */
    }
    return json;
  },

  // usage app.__vue__.$utils.getFromPath({a:{b:{c:"hello"}}},"a.b.c")
  getFromPath(obj, path) {
    // unicode letters used for parsing html tags and property paths.
    // using https://www.w3.org/TR/html53/semantics-scripting.html#potentialcustomelementname
    var unicodeRegExp = /a-zA-Z\u00B7\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u037D\u037F-\u1FFF\u200C-\u200D\u203F-\u2040\u2070-\u218F\u2C00-\u2FEF\u3001-\uD7FF\uF900-\uFDCF\uFDF0-\uFFFD/;
    // it returns a function used to parse a object path     
    function _p(p) {
      var re = new RegExp("[^".concat(unicodeRegExp.source, ".$_\\d]"));
      if (re.test(p)) return;
      var s = p.split('.');
      return function(o) {
        for (var i = 0; i < s.length; i++) {
          if (!o) return;
          o = o[s[i]];
        }
        return o;
      };
    }
    return _p(path)(obj)
  },

  queryStrAtr: queryStrAtr,

  // list of colors for chart and history panels
  colors() {
    return [
      "#9966ff",
      "#ffce56",
      "#36a2eb",
      "#4bc0c0",
      "#ff9f40",
      "#9966ff",
      "#ffce56",
      "#36a2eb",
      "#4bc0c0",
      "#ff9f40",
      "#9966ff",
      "#ffce56",
      "#36a2eb",
      "#4bc0c0",
      "#ff9f40",
      "#9966ff",
      "#ffce56",
      "#36a2eb",
      "#4bc0c0",
      "#ff9f40",
      "#9966ff",
      "#ffce56",
      "#36a2eb",
      "#4bc0c0",
      "#ff9f40",
      "#9966ff",
      "#ffce56",
      "#36a2eb",
      "#4bc0c0",
      "#ff9f40"
    ];
  },

  isLocal() {
    return document.location.hostname.match(
      /^localhost$|^127(?:\.[0-9]+){0,2}\.[0-9]+$|^(?:0*:)*?:?0*1$/
    )
      ? true
      : false;
  },

  uuid: () => ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
  ),

  async injectScript(src) {
    return new Promise((resolve, reject) => {
      if (src) {
        if (src.indexOf("http") == 0) {
          const script = document.createElement("script");
          script.async = true;
          script.src = src;
          script.addEventListener("load", (e) => {
            resolve(e);
          });
          script.addEventListener("error", () =>
            reject("Error loading script.")
          );
          script.addEventListener("abort", () =>
            reject("Script loading aborted.")
          );
          document.head.appendChild(script);
        } else {
          document.body.insertAdjacentHTML("beforeend", src);
          resolve("Injected");
          // var template = document.createElement('template');
          // template.innerHTML = src.trim();
          // template.content.firstChild;
        }
      } else {
        reject("Empty?");
      }
    });
  },

  scientificToDecimal(num) {
    var nsign = Math.sign(num);
    //remove the sign
    num = Math.abs(num);
    //if the number is in scientific notation remove it
    if (/\d+\.?\d*e[+-]*\d+/i.test(num)) {
      var zero = "0",
        parts = String(num)
          .toLowerCase()
          .split("e"), //split into coeff and exponent
        e = parts.pop(), //store the exponential part
        l = Math.abs(e), //get the number of zeros
        sign = e / l,
        coeff_array = parts[0].split(".");
      if (sign === -1) {
        l = l - coeff_array[0].length;
        if (l < 0) {
          num =
            coeff_array[0].slice(0, l) +
            "." +
            coeff_array[0].slice(l) +
            (coeff_array.length === 2 ? coeff_array[1] : "");
        } else {
          num = zero + "." + new Array(l + 1).join(zero) + coeff_array.join("");
        }
      } else {
        var dec = coeff_array[1];
        if (dec) l = l - dec.length;
        if (l < 0) {
          num = coeff_array[0] + dec.slice(0, l) + "." + dec.slice(l);
        } else {
          num = coeff_array.join("") + new Array(l + 1).join(zero);
        }
      }
    }
    return nsign < 0 ? "-" + num : num;
  },

  loadImage(src, b64) {
    return new Promise((resolve) => {
      if (src.indexOf("_ts=") > 0) {
        resolve({ src: src });
      } else {
        let img = new Image();
        img.crossOrigin = "anonymous";
        img.onload = function() {
          if (b64) {
            let canvas = document.createElement("CANVAS");
            let ctx = canvas.getContext("2d");
            canvas.height = this.naturalHeight;
            canvas.width = this.naturalWidth;
            ctx.drawImage(this, 0, 0);
            let dataURL = canvas.toDataURL();
            canvas = null;
            resolve({ src: dataURL });
          } else {
            resolve(img);
          }
        };
        img.src = src + "?_ts=" + new Date().getTime();
      }
    });
  },

  toDataUrl(src, callback) {
    let img = new Image();
    img.onload = function() {
      let canvas = document.createElement("CANVAS");
      let ctx = canvas.getContext("2d");
      let dataURL = undefined;
      canvas.height = this.naturalHeight;
      canvas.width = this.naturalWidth;
      ctx.drawImage(this, 0, 0);
      dataURL = canvas.toDataURL();
      callback(dataURL);
      canvas = null;
    };
    img.src = src;
    if (img.complete || img.complete === undefined) {
      img.src =
        "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw=="; // flush it
      img.src = src; // try once again
    }
  },

  async imagesToBase64(container) {
    let imageList = (container && container.getElementsByTagName("img")) || [];
    if (!imageList.length) this.return;
    let promises = [];
    for (let i in imageList) {
      let img = imageList[i];
      if ((img.src || "").match(/(http|https):\/\//)) {
        promises.push(
          new Promise((resolve, reject) => {
            try {
              let canvas = document.createElement("CANVAS");
              let ctx = canvas.getContext("2d");
              canvas.height = img.naturalHeight;
              canvas.width = img.naturalWidth;
              ctx.drawImage(img, 0, 0);
              img.src = canvas.toDataURL();
              resolve(img);
            } catch (e) {
              reject(e);
            }
          })
        );
      }
    }
    Promise.all(promises).then(() => { });
  },

  async screenshot(container) {
    return new Promise((resolve, reject) => {
      if (container) {
        if (container) {
          window
            .html2canvas(container, {
              useCORS: true,
              allowTaint: true
            })
            .then((canvas) => {
              resolve(canvas.toDataURL());
            });
        }
      } else {
        reject(null);
      }
    });
  },

  fullscreen(el, callback) {
    let _cb = callback || function() { };
    if (
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement ||
      document.msFullscreenElement
    ) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    } else {
      if (!document.onfullscreenchange) {
        document.onfullscreenchange = () => {
          if (document.fullscreenElement) {
            _cb(true); // fullscreen = true
          } else {
            _cb(false); // fullscreen = false
          }
        };
      }
      //===========================================
      if (el.requestFullscreen) {
        el.requestFullscreen();
      } else if (el.mozRequestFullScreen) {
        el.mozRequestFullScreen();
      } else if (el.webkitRequestFullscreen) {
        el.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
      } else if (el.msRequestFullscreen) {
        el.msRequestFullscreen();
      }
    }
  },

  distinct(lst) {
    return lst.filter((i, ix, self) => (typeof (i) == 'object' && i?.id !== undefined ? self.findIndex(({ id }) => id == i?.id) == ix : self.indexOf(i) == ix));
  },

  // credit: https://stackoverflow.com/questions/5069464/replace-multiple-strings-at-once
  replaceBulk(str, findArray, replaceArray) {
    var i,
      regex = [],
      map = {};
    for (i = 0; i < findArray.length; i++) {
      regex.push(findArray[i].replace(/([-[\]{}()*+?.\\^$|#,])/g, "\\$1"));
      map[findArray[i]] = replaceArray[i];
    }
    regex = regex.join("|");
    str = str.replace(new RegExp(regex, "g"), function(matched) {
      return map[matched];
    });
    return str;
  },

  // app.__vue__.$utils.isTrue("$value>20", {$value: 100})
  isTrue(exp, data) {
    return this.eval(exp, data) ? true : false;
  },

  parseJSArgs(jsdoc, context) {
    var args = [];
    var variables = [];
    Object.keys(context || {}).forEach(($v) => {
      if (jsdoc.indexOf($v) >= 0 && !(/(\s+|\r?\n|\r|\t)/.test($v))) {
        args.push($v);
        variables.push(context[$v])
      }
    })
    // it forces to make use of type safe comparation
    let script = jsdoc.replace(/(\!==|\!=)/g, "§§§").replace(/===/g, "@@@").replace(/(==)|(@@@)/g, "===").replace(/§§§/g, "!==");
    return { args: args, vars: variables, script: script };
  },

  /*
    execute a js script
    app.__vue__.$utils.js(
      'var s=0;for(var i=0;i<$a;i++){ s+=$a*($b+1);console.log(s);};return s;'
    ,
    {'$a':10, '$b':2}
    )
    important: do not throw or catch exceptions (client must treat it accordingly)
  */
  js(docjs, context) {

    var ref = this.parseJSArgs(docjs, context);
    var args = ref.args,
      variables = ref.vars,
      script = ref.script;
    args.push(`return ()=>{${script}};`);
    let fn = Function.apply(null, args);
    let $fn = fn.apply(this, variables);
    if (typeof $fn == "function") {
      return $fn();
    }
  },

  //
  // MAZ
  // Evaluates a js code and preserve context reference 
  // IMPORTANT: It does not build a protected and scoped js enviroment such as buildJSFunction does
  //
  // basic example: 
  // app.__vue__.$utils.eval('$a+$b',{'$a':10, '$b':2})
  // returns 12
  //
  // object instances (even functions) are preserved 
  // example: app.__vue__.$utils.eval('lst.filter((item)=>item.a==1)',{lst:[{a:0},{a:1},{a:3},{a:1,c:()=>"oi"},{a:0}]})
  // returns [{a:1},{a:1,c:()=>"oi"]
  eval(docjs, context) {
    var ref = this.parseJSArgs(docjs, context);
    var args = ref.args,
      variables = ref.vars,
      script = ref.script;
    if (script.indexOf("${") >= 0 && (!/^`.*`$/.test(this.trim(script)))) {
      script = "`" + script + "`";
    };
    args.push("return " + script);
    try {
      let fn = Function.apply(null, args);
      return fn.apply(this, variables);
    } catch (e) {
      return "";
    }
  },

  // Build a function library from given object/text
  // test
  // r=app.__vue__.$utils.buildJSLib(app.__vue__.$store.getters["scripts/globalFunctions"])
  // r.$a(1)
  buildJSLib(context) {
    const entry = typeof (context) == "object" && context || JSON.stringify(context)
    let keys = Object.keys(entry || {});
    let ret = {};
    (keys).forEach((key) => {
      try {
        // console.log(key)
        ret[key] = Function("return (" + entry[key] + ")")()
      }
      catch (e) {
        console.log(`invalid function ${key}`);
        console.log(e)
        throw { name: key, detail: e }
      }
    });
    return ret;
  },

  /*
  evaluates a chain of expressions based on js template strings
  property value of a given object by walking on property path and applying any provided sprint format
  
  context: {"data":{"name":"temperature","stats":{"average": -273.15}}}
  expression:  "${data?.stats?.average*1.8+32}| Temperature %.2f F°"
  substitutions: {$value:'data?.current_value?.value', $connectorName:'data?.device?.connector?.name'}

  example: 
    app.__vue__.$utils.evaluate(
      {"data":{"name":"temperature","stats":{"average": -273.15}}},
      "${data?.stats?.average*1.8+32}|<i class='fa-line-chart'></i>Temperature %.2f F°"
    )

  */
  evaluate(context, expression, globals) {
    let template = this.trim(expression || "");
    if (template && Object.getOwnPropertyNames(context || {}).length) {
      let env = {
        ...globals || {},
        ...context || {},
      }
      let v = template.replace(/\|\|/g, "§").split("|"); // preserve ||
      let tpl = this.trim(v[0].replace(/§/g, "||"));
      let format = v.length > 1 ? v[1] : "";
      let vlr = "";
      try {
        // No root node is required anymore, although to prevent backward compatibility issues
        // we remove any remaining reference to "item?."
        tpl = tpl.replace(/item(\.|\?\.)/g, "");
        // tpl = tpl.indexOf("${") >= 0 ? tpl : `\$\{${tpl}\}`;
        // vlr = this.buildJSFunction(tpl, env || {})();
        vlr = this.eval(tpl, env)
      } catch (e) {
        vlr = "";
      }
      if (vlr === "undefined") {
        vlr = undefined;
      } else if (vlr !== "" && format.indexOf("%") >= 0 && !isNaN(vlr)) {
        vlr = this.sprintf(format, parseFloat(vlr)) || vlr;
      }
      return /(function|\=\>)/.test(vlr) ? expression : vlr;
    } else {
      return expression;
    }
  },

  evaluateAndFormat(obj, expression, defaultValue) {
    let vlr = defaultValue;
    if (!expression) return vlr;
    let exp = "";
    let fmt = "";
    let p = expression.split("|");
    if (expression.indexOf("${") >= 0) {
      exp = p[0];
      if (p.length > 1) {
        fmt = p[1];
      }
    } else {
      fmt = p[0];
    }
    if (exp) {
      // always data
      let data = obj;
      vlr = this.evaluate(data, exp);
    }
    if (fmt && vlr !== "" && !isNaN(vlr)) {
      let num = parseFloat(vlr);
      if (fmt.match(/(DD|YY|MM|HH|mm|ss)/) && window.moment) {
        vlr = window.moment(num).format(fmt);
      } else if (fmt.indexOf("%") >= 0) {
        vlr = this.sprintf(fmt, num) || vlr;
      }
    }
    return vlr;
  },

  /*
  returns an interpolated array of values
  example: 
    lst=[1,5,3]    
    size=3  result=[1,5,3]
    size=4  result=[1, 3.6666666666666665, 4.333333333333334, 3]
    size=5  result=[1, 3, 5, 4, 3]
  */
  interpolate(lst, size) {
    var sf = (lst.length - 1) / (size - 1);
    var tmp, pvr, aft, atp;
    var r = [lst[0]];
    for (var i = 1; i < size - 1; i++) {
      tmp = i * sf;
      pvr = Math.floor(tmp);
      aft = Math.ceil(tmp);
      atp = tmp - pvr;
      r[i] = lst[pvr] + (lst[aft] - lst[pvr]) * atp;
    }
    r[size - 1] = lst[lst.length - 1];
    return r;
  },

  closestByClass(el, className) {
    let _p = el;
    while (!_p.classList.contains(className)) {
      _p = _p.parentElement;
      if (!_p) break;
    }
    return _p;
  },

  /*
  credit:
  https://stackoverflow.com/questions/14344319/can-i-be-notified-of-cookie-changes-in-client-side-javascript  
  */
  initCookieEvents(interval) {
    let lastCookie = document.cookie;
    setInterval(() => {
      let cookie = document.cookie;
      if (cookie !== lastCookie) {
        try {
          document.dispatchEvent(
            new CustomEvent("cookiechange", {
              detail: { oldValue: lastCookie, newValue: cookie }
            })
          );
        } finally {
          lastCookie = cookie;
        }
      }
    }, interval || 1000);
  },

  // to stop observing, you must call:
  // sizeWatcher.unobserve(target)
  elSizeObserver(target, onChanged) {
    let rect = target?.getBoundingClientRect() || null;
    if (rect) {
      let _cb = onChanged || function() { };
      let _size = { width: rect.width, height: rect.height };
      const sizeWatcher = new ResizeObserver((els) => {
        let el = els && els.length ? els[0] : null;
        let r = (el && el?.target?.getBoundingClientRect()) || null;
        if (r) {
          let h = parseInt(r.height || 0);
          let w = parseInt(r.width || 0);
          if (w != _size.height || w != _size._width) {
            _size.height = h;
            _size.width = w;
            if (w && h) {
              _cb(_size);
            }
          }
        }
      });
      sizeWatcher.observe(target);
      return sizeWatcher;
    }
    return null;
  },

  // build a tree from list of objects based on aggregation attributes
  // let groups = ["group1", "group2"];
  /*
  list = [  
    {
      campo1: "valor01",
      campo2: "valor02",
      group1: "a",
      group2: "aa",
      group3: "xa"
    },
    {
      campo1: "valor11",
      campo2: "valor12",
      group1: "a",
      group2: "bb",
      group3: "xa"
    },
    {
      campo1: "valor21",
      campo2: "valor22",
      group1: "a",
      group2: "aa",
      group3: "xb"
    }
  ]
  */
  tree(items, fields) {
    items = items || [];
    let root = null;

    let groups = (
      fields.filter((f) => (f?.aggregation?.enabled ? true : false)) || []
    ).map(({ name }) => name);

    if (!groups.length) return null;

    let field = null;
    const _groupBy = (lst, attr) => {
      let obj = {};
      lst.forEach((item) => {
        field = fields.find(({ name }) => name == attr) || null;
        let k = field && field.parser ? field.parser(item) : item[attr];
        obj[k] = obj[k] || [];
        obj[k].push(item);
      });
      return obj;
    };
    // const _filter = (lst, attr)=>lst.filter( (i) => (i||{})[attr]?true:false );
    const _filter = (lst, attr) =>
      lst.filter((item) => {
        field = fields.find(({ name }) => name == attr) || null;
        let k = field && field.parser ? field.parser(item) : item[attr];
        return k !== "" && k !== undefined && k !== null;
      });
    let lst;
    const _tree = (node, ix) => {
      ix = ix || 0;
      if (!(groups.length > ix)) return;
      if (ix) {
        for (var k in node) {
          lst = _filter(node[k], groups[ix]);
          if (lst.length) {
            node[k] = _groupBy(lst, groups[ix]);
            _tree(node[k], ix + 1);
          }
        }
      } else {
        lst = _filter(node, groups[ix], groups[ix]);
        if (lst.length) {
          root = _groupBy(lst, groups[ix]);
          _tree(root, ix + 1);
        }
      }
    };
    if (groups.length) {
      _tree(items);
    }
    return root;
  },

  utf_atob: (b64) => {
    /* source: https://developer.mozilla.org/en-US/docs/Glossary/Base64 */

    const b64ToUint6 = (c) => {
      return c > 64 && c < 91
        ? c - 65
        : c > 96 && c < 123
          ? c - 71
          : c > 47 && c < 58
            ? c + 4
            : c === 43
              ? 62
              : c === 47
                ? 63
                : 0;
    };

    const base64DecToArr = (sBase64, nBlocksSize) => {
      var sB64Enc = sBase64.replace(/[^A-Za-z0-9\+\/]/g, ""),
        nInLen = sB64Enc.length,
        nOutLen = nBlocksSize
          ? Math.ceil(((nInLen * 3 + 1) >> 2) / nBlocksSize) * nBlocksSize
          : (nInLen * 3 + 1) >> 2,
        taBytes = new Uint8Array(nOutLen);

      for (
        var nMod3, nMod4, nUint24 = 0, nOutIdx = 0, nInIdx = 0;
        nInIdx < nInLen;
        nInIdx++
      ) {
        nMod4 = nInIdx & 3;
        nUint24 |= b64ToUint6(sB64Enc.charCodeAt(nInIdx)) << (6 * (3 - nMod4));
        if (nMod4 === 3 || nInLen - nInIdx === 1) {
          for (nMod3 = 0; nMod3 < 3 && nOutIdx < nOutLen; nMod3++, nOutIdx++) {
            taBytes[nOutIdx] = (nUint24 >>> ((16 >>> nMod3) & 24)) & 255;
          }
          nUint24 = 0;
        }
      }
      return taBytes;
    };

    const UTF8ArrToStr = (aBytes) => {
      var sView = "";
      for (var nPart, nLen = aBytes.length, nIdx = 0; nIdx < nLen; nIdx++) {
        nPart = aBytes[nIdx];
        sView += String.fromCodePoint(
          nPart > 251 && nPart < 254 && nIdx + 5 < nLen /* six bytes */
            ? /* (nPart - 252 << 30) may be not so safe in ECMAScript! So...: */
            (nPart - 252) * 1073741824 +
            ((aBytes[++nIdx] - 128) << 24) +
            ((aBytes[++nIdx] - 128) << 18) +
            ((aBytes[++nIdx] - 128) << 12) +
            ((aBytes[++nIdx] - 128) << 6) +
            aBytes[++nIdx] -
            128
            : nPart > 247 && nPart < 252 && nIdx + 4 < nLen /* five bytes */
              ? ((nPart - 248) << 24) +
              ((aBytes[++nIdx] - 128) << 18) +
              ((aBytes[++nIdx] - 128) << 12) +
              ((aBytes[++nIdx] - 128) << 6) +
              aBytes[++nIdx] -
              128
              : nPart > 239 && nPart < 248 && nIdx + 3 < nLen /* four bytes */
                ? ((nPart - 240) << 18) +
                ((aBytes[++nIdx] - 128) << 12) +
                ((aBytes[++nIdx] - 128) << 6) +
                aBytes[++nIdx] -
                128
                : nPart > 223 && nPart < 240 && nIdx + 2 < nLen /* three bytes */
                  ? ((nPart - 224) << 12) +
                  ((aBytes[++nIdx] - 128) << 6) +
                  aBytes[++nIdx] -
                  128
                  : nPart > 191 && nPart < 224 && nIdx + 1 < nLen /* two bytes */
                    ? ((nPart - 192) << 6) + aBytes[++nIdx] - 128 /* nPart < 127 ? */
                    : /* one byte */
                    nPart
        );
      }
      return sView;
    };
    try {
      return UTF8ArrToStr(base64DecToArr(b64));
    } catch (e) {
      return decodeURIComponent(atob(b64));
    }
  },
  JSONDragger: (config) => {
    // simple object for managing draggable JSON content
    let img = null;
    if (config && config.image_url) {
      img = new Image();
      img.src = config.image_url;
    }
    const onDragging = config && typeof (config.onDragging) && config.onDragging || ((e) => { });
    return {
      dragging: false,
      opacity: "inherit",
      image: img,
      onDragStart: function(e, type, data) {
        this.dragging = true;
        this.opacity = e.target.style.opacity;
        onDragging(this.dragging);
        e.dataTransfer.effectAllowed = "move";
        e.target.style.opacity = "0.4";
        e.dataTransfer.setData(
          "text/plain",
          JSON.stringify({
            type: type,
            data: data
          })
        );
        if (this.image) {
          e.dataTransfer.setDragImage(this.image, 50, 50);
        }
      },
      onDragEnd: function(e) {
        this.dragging = false;
        onDragging(this.dragging);
        e.target.style.opacity = this.opacity;
        this.opacity = "inherit";
      },
      onDragOver: (e) => {
        e.preventDefault();
      },
      onDrop: function(e, handlers) {
        onDragging(false);
        if (!e) return;
        e.preventDefault();
        e.stopPropagation();
        try {
          e.target.style.opacity = this.opacity;
          this.opacity = "inherit";
          const data = e.dataTransfer.getData("text/plain") || null;
          if (data) {
            let entry = JSON.parse(data);
            if (entry && entry.data && entry.type && entry.type in handlers && typeof handlers[entry.type] == 'function') {
              handlers[entry.type](e, entry.data);
            }
            else {
              throw "invalid content"
            }
          }
        } catch (e) {
          console.log(e);
        }
        return false;
      }
    };
  },

  // Confirm removal with sweet alert plugin
  confirmRemoval: (ctx, option) => {
    let item = option.item; // the object entity
    let type = option.type; // the type of entity ("connector" | "device" | "data" | "alarm" | etc....)

    const warningContent = (label, value, message, icon) => {
      let field_name =
        label instanceof Array ? ctx.$tc(label[0], label[1]) : ctx.$tc(label);
      let warning = "";
      if (message) {
        let text = ctx.$tc(message);
        let cls = icon || "fa fa-exclamation-triangle";
        warning = `<br/><div class="text-warning"><i class="${cls}"></i> ${text}</div>`;
      }
      let wrapper = document.createElement("div");
      wrapper.innerHTML = `<b>${field_name}</b>: ${value}${warning}`;
      return wrapper;
    };

    return new Promise((resolve) => {
      ctx
        .$swal({
          title: ctx.$t("are_you_sure"),
          content: warningContent(
            type,
            item.name,
            "you_wont_be_able_to_revert_this"
          ),
          icon: "warning",
          buttons: [ctx.$t("cancel"), ctx.$t("yes_delete_it")]
        })
        .then((confirmed) => {
          resolve(confirmed);
        });
    });
  },

  confirm: (ctx, message, title) => {
    const warningContent = (message, icon) => {
      let warning = "";
      if (message) {
        let text = ctx.$tc(message);
        let cls = icon || "fa fa-exclamation-triangle";
        warning = `<div class="text-bold" style="color:#56707c"><i class="${cls}"></i> ${text}</div>`;
      }
      let wrapper = document.createElement("div");
      wrapper.innerHTML = `${warning}`;
      return wrapper;
    };

    return new Promise((resolve) => {
      ctx
        .$swal({
          title: ctx.$t(title ? title : "are_you_sure"),
          content: warningContent(message),
          icon: "warning",
          buttons: [ctx.$t("cancel"), ctx.$t("yes_proceed")]
        })
        .then((confirmed) => {
          resolve(confirmed);
        });
    });
  },

  // notify user
  notifyUser: (ctx, msg, options) => {
    var config = {
      ...{
        type: "success",
        icon: "code",
        iconPack: "fontawesome",
        duration: 3000,
        singleton: true,
        position: "bottom-right",
        action: {
          icon: "fa-close",
          onClick: (e, me) => {
            me.goAway(0);
          }
        }
      },
      ...(options || {})
    }
    if (config.clear) {
      ctx.$toasted.clear();
      delete config.clear;
    }
    ctx.$toasted.show(msg, config);
  },

  // return a list of hashtags or convert them to simple tags (lower case without hash mark)
  hashtags(text, simplify) {
    return hashtags(text, simplify);
  },

  currentMask(masks, value) {
    // Empty / Null / Invalid masks return empty string
    if (!Array.isArray(masks) || masks == []) {
      return masks + "";
    }

    // Remove previous formatting
    value = onlyNumbers(value);

    // Mask being compared
    let current_mask = '';
    // Number of "underlines" in current mask
    let total_empty_slots = 0;

    // Check every mask in order. First one that fits is used
    for (let i = 0; i < masks.length; i++) {
      try {
        current_mask = masks[i];
        total_empty_slots = current_mask.match(/_/g).length;
      } catch {
        return masks + "";
      }
      // Find right mask by length or get the last one
      if (value.length <= total_empty_slots || i == (masks.length - 1)) {
        return current_mask;
      }
    }
  },

  formatMask(masks, value, crop = false) {
    // Empty / Null / Invalid masks return empty string
    if (!Array.isArray(masks) || masks == []) {
      return "";
    }

    // Remove previous formatting
    value = onlyNumbers(value);

    // Mask being compared
    let current_mask = '';
    // Lenght of the current mask
    let size_mask = 0;
    // Number of "underlines" in current mask
    let total_empty_slots = 0;
    // Final mask with $n
    let builded_mask = '';
    // Regular expression to obtain blocks of numbers from input value
    let builded_regex = '';
    // Number of sequential "underlines"
    let empty_slot_counter = 0;
    // Counter for blocks of sequential "underlines"
    let block_counter = 1;

    // Check every mask in order. First one that fits is used
    for (let i = 0; i < masks.length; i++) {
      try {
        current_mask = masks[i];
        size_mask = current_mask.length;
        total_empty_slots = current_mask.match(/_/g).length;
      } catch {
        return "";
      }
      // Find right mask by length or get the last one
      if (value.length <= total_empty_slots || i == (masks.length - 1)) {
        // Build mask RegEx
        for (let j = 0; j < size_mask; j++) {
          // Empty slots are counted. If last character, concatenates
          if (current_mask[j] == '_') {
            empty_slot_counter++;

            if (j == (size_mask - 1)) {
              builded_mask += "\$" + block_counter;
              builded_regex += "(\\w{" + empty_slot_counter + "})";
            }
          } else {
            if (empty_slot_counter > 0) {
              builded_mask += "\$" + block_counter + current_mask[j];
              builded_regex += "(\\w{" + empty_slot_counter + "})";
              block_counter++;
              empty_slot_counter = 0;

            } else {
              builded_mask += current_mask[j];
            }
          }
        }

        // Fill value with "underlines", then slice it to maximum size
        value += '_'.repeat(total_empty_slots).slice(0, total_empty_slots);

        // Apply the builded Regular Expression to value, resulting in the formatted value
        let formatted_value = value.replace(new RegExp(builded_regex), builded_mask).slice(0, size_mask);

        // If needed only until the last valid character
        if (crop) {
          let index = formatted_value.length;

          for (let i = index - 1; i >= 0; i--) {
            if (onlyNumbers(formatted_value[i]) != "") {
              break;
            }
            index--;
          }
          return formatted_value.slice(0, index);
        } else {
          return formatted_value;
        }

      }
    }
  },

  setCaretPosition(elem, caretPos) {
    if (elem != null) {
      if (elem.createTextRange) {
        let range = elem.createTextRange();
        range.move('character', caretPos);
        range.select();
      }
      else {
        if (elem.selectionStart) {
          elem.focus();
          elem.setSelectionRange(caretPos, caretPos);
        }
        else {
          elem.focus();
        }
      }
    }
  },
  validateCpfCnpj(cpf_cnpj) {
    if (typeof (cpf_cnpj) == "undefined" || !cpf_cnpj) return false;
    cpf_cnpj = onlyNumbers(cpf_cnpj);

    if (cpf_cnpj.length < 12) {
      // Validate CPF
      if (cpf_cnpj.length == 0 || cpf_cnpj.length < 11) return false;
      const validate = cpf => checkAll(prepare(cpf));
      const notDig = i => !['.', '-', ' '].includes(i);
      const prepare = cpf => cpf.trim().split('').filter(notDig).map(Number);
      const is11Len = cpf => cpf.length === 11;
      const notAllEquals = cpf => !cpf.every(i => cpf[0] === i);
      const onlyNum = cpf => cpf.every(i => !isNaN(i));
      const calcDig = limit => (a, i, idx) => a + i * ((limit + 1) - idx);
      const somaDig = (cpf, limit) => cpf.slice(0, limit).reduce(calcDig(limit), 0);
      const resto11 = somaDig => 11 - (somaDig % 11);
      const zero1011 = resto11 => [10, 11].includes(resto11) ? 0 : resto11;
      const getDV = (cpf, limit) => zero1011(resto11(somaDig(cpf, limit)));
      const verDig = pos => cpf => getDV(cpf, pos) === cpf[pos];
      const checks = [is11Len, notAllEquals, onlyNum, verDig(9), verDig(10)];
      const checkAll = cpf => checks.map(f => f(cpf)).every(r => !!r);
      return validate(cpf_cnpj);

    } else {
      // Validate CNPJ
      const numbers = cpf_cnpj.split('').map(Number);
      if (numbers.length !== 14) return false;
      const items = [...new Set(cpf_cnpj)];
      if (items.length === 1) return false;
      const calc = (x) => {
        const slice = numbers.slice(0, x);
        let factor = x - 7;
        let sum = 0;
        for (let i = x; i >= 1; i--) {
          const n = slice[x - i];
          sum += n * factor--;
          if (factor < 2) factor = 9;
        }
        const result = 11 - (sum % 11);
        return result > 9 ? 0 : result;
      };
      const digits = numbers.slice(12);
      const digit0 = calc(12);
      if (digit0 !== digits[0]) return false;
      const digit1 = calc(13);
      return digit1 === digits[1];
    }
  },
  validateEmail(email) {
    if (typeof (email) == "undefined") return false;
    if (email == null || email.trim().length == 0) return false;
    let result = email
      .trim()
      .toLowerCase()
      .match(
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
      );
    if (typeof (result) == "undefined" || result == null) return false;
    if (result.length == 0) return false;
    return result[0] != [];
  },
  validateZipcode(zipcode) {
    return zipcode && onlyNumbers(zipcode).length == 8;
  },
  validatePhone(phone) {
    phone = onlyNumbers(phone);
    return phone && phone.length >= 10 && phone.length <= 11;
  },
  formatMoney(v, sign = true) {
    if (!v && v != 0 || typeof v == "undefined") return "";
    if (onlyNumbers(v) == "") return "";

    let result = parseFloat(v).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });

    if (!result.includes('R$')) {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2,
      });
      result = formatter.format(v).replace('R$', 'R$ ');
    }

    if (!sign) return result.replace("R$", "").trim();

    return result;
  },

  randomColor() {
    return '#' + (Math.random() * 0xFFFFFF << 0).toString(16).padStart(6, '0');
  },

  loadCSS: loadCSS,
  applyCSSTheme: applyCSSTheme
};